<template>
  <div class="Project">
    <div>
      <h5>Project</h5>
      <hr />
    </div>
    <ul class="p-0 d-flex flex-wrap box-sizing">
      <li class="img-box" v-for="index in 6" :key="index">
        <img
          src="@/assets/images/user/01.jpg"
          alt="gallary-image"
          class="img-fluid"
        />
        <h6 class="mt-2">friend.nick_name</h6>
      </li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.img-box {
  margin: 5px 5px 15px;
  text-align: center;
  .img-fluid {
    width: 224px;
    height: 150px;
  }
}
</style>