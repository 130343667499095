<template>
  <div class="post-detail">
    <div class="d-flex black-mask p-0 m-0">
      <div class="left-swiper p-0">
        <el-image-viewer
          v-if="showViewer"
          :on-close="closeViewer"
          :url-list="imageList"
          :on-switch="onSwitch"
          :initialIndex="nowIndex"
        >
        </el-image-viewer>
        <div class="small-img-box">
          <ul class="m-0 p-0">
            <li
              :style="{ borderColor: index == nowIndex ? '#59b9ff' : '#fff' }"
              v-for="(item, index) in imgList"
              :key="index"
            >
              <!-- @click="changeImg(index)" -->
              <img :src="item.url" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="right-comment">
        <!-- avatar -->
        <div class="d-flex align-items-center">
          <div class="user_img mr-3">
            <img class="avatar-60 rounded-circle" :src="postDetail.avatar" />
          </div>
          <div class="nick_name_box">
            <h3 style="width: 100%" class="text-overflow-single-self" :title='postDetail.nick_name'>
              {{ postDetail.nick_name }}
            </h3>
            <p class="m-0 p-0">{{ postDetail.created_at }}</p>
          </div>
        </div>

        <!-- content -->
        <p v-html="postContentFilter(postDetail.content)" class="post_content">
        </p>

        <!-- icon -->
        <ul class="like_comment_share p-0">
          <li v-if="postDetail.is_user_send">
            <i @click="$parent.deletePost(postDetail)" class="las la-trash"></i>
          </li>
          <li @click="$parent.shareStaus(postDetail)" v-else>
            <i class="ri-share-line"></i>
            <span class="ml-1">{{ postDetail.share_total }} </span>
          </li>
          <li @click="showComment = !showComment">
            <i class="ri-message-3-line"></i>
            <span>{{ postDetail.comment_total }}</span>
          </li>
          <li
            :class="{ 'text-primary': postDetail.user_post_like }"
            @click="$parent.likePost(postDetail)"
          >
            <i class="ri-thumb-up-line"></i>
            <span class="mr-1">{{ postDetail.like_total }} </span>
          </li>
        </ul>

        <hr style="border-color: #f1f1f1" />

        <!-- 顶级评论 -->
        <div v-if="showComment">
          <!-- 大评论框 -->
          <!-- 评论的图片列表 -->
          <ul class="p-0 m-0 media_box">
            <li
              class="li_pic"
              v-for="(item, index) in commentMedia"
              :key="index"
            >
              <img v-if="item.type == 'photo'" :src="item.url" alt="" />
              <video v-else :src="item.url" controls autoplay></video>
              <!-- <img class="close_img" src="../../../assets/images/add_post_close.png" @click="unshiftMedia(index)" alt="" /> -->
              <img
                class="close_img"
                src="../../../../assets/images/add_post_close.png"
                @click="commentMedia.splice(index, 1)"
                alt=""
                style="cursor: pointer"
              />
              <!-- <i class="las la-times-circle" ></i> -->
            </li>
          </ul>
          <b-form
            @submit.prevent
            class="comment-text d-flex align-items-center pb-3"
          >
            <b-form-input
              type="text"
              v-model="commentPostContent"
              class="rounded"
            />
            <!-- @keyup.enter.native="commentPost(post)" -->
            <div class="comment-attagement d-flex">
              <el-upload
                class="avatar-uploader"
                action="no"
                :http-request="imgUpload"
                :show-file-list="false"
              >
                <i class="ri-camera-line mr-2"></i>
              </el-upload>
              <button
                :disabled="!commentPostContent && !commentMedia.length"
                class="btn btn-primary"
                @click="commentPost(postDetail)"
              >
                {{ $t("Posts.a98@send") }}
              </button>
            </div>
          </b-form>
        </div>

        <!-- 顶级评论 -->
        <ul
          style="height: calc(100% - 201px); overflow: scroll"
          ref="firstComment"
          class="comment_and_replay p-0"
        >
          <li
            v-for="postComment in postDetail.firstCommentList"
            :key="postComment.id"
          >
            <div class="d-flex justify-content-between align-items-top">
              <div class="user_avatar mr-2">
                <img :src="postComment.avatar" alt="" />
              </div>
              <div class="comment_content">
                <div class="comment_main">
                  <span>{{ postComment.nick_name }}</span
                  >:
                  {{
                    postComment.content &&
                    JSON.parse(postComment.content).content
                  }}
                  <ul
                    v-if="
                      postComment.content &&
                      JSON.parse(postComment.content).img &&
                      JSON.parse(postComment.content).img.length
                    "
                    class="simple_img d-flex p-0 m-0"
                  >
                    <li class="pr-3" style="color: #50b5ff; cursor: pointer">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(postComment.content)"
                        >View</span
                      >
                    </li>
                  </ul>
                </div>
                <div class="time_icon">
                  <span>{{ postComment.created_at }}</span>
                  <ul class="m-0 p-0">
                    <li
                      v-show="postComment.is_user_send"
                      @click="delSelfPostComment(postComment)"
                    >
                      <i class="ri-delete-bin-line"></i>
                    </li>
                    <li @click="$parent.openReplayComment(postComment)">
                      <i class="ri-message-3-line"></i>
                    </li>
                    <li
                      :class="{
                        'text-secondary': !postComment.is_like,
                        'text-primary': postComment.is_like,
                      }"
                      @click="$parent.likePostComment(postComment)"
                    >
                      <i class="ri-thumb-up-line"></i>
                      {{ postComment.like_total }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- 二级评论列表入口---gmc等人共8条回复-->
            <div v-if="postComment.comment_total" class="second_comment">
              <span class="mr-1">{{ postComment.comment_user_name }}</span>
              Et
              <span
                class="ml-1"
                @click="$parent.secondCommentOpen(postComment)"
              >
                all {{ postComment.comment_total
                }} {{ $t("Posts.e80@reply") }}</span
              >
              <i class="ri-arrow-down-s-fill"></i>
            </div>
          </li>
        </ul>
      </div>

      <!-- view-picture -->
      <viewPicture
        v-if="isShowViewPicture"
        :mediaListProp="viewPictureImg"
        @closeViewPhoto="closeViewPhoto"
        style="width: 100%; position: fixed; top: 0; left: 0; z-index: 9999"
      />
    </div>
  </div>
</template>

<script>
import {
  throttle
} from '@/Utils/globalFn'
import viewPicture from '@/components/ComComponents/previewPicture'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    viewPicture,
    ElImageViewer
  },
  props: {
    postDetail: {
      type: Object,
      default: {}
    },
    mediaIndex: {
      type: Number,
      default: 0
    }

  },
  data () {
    return {
      page: 1,
      totalPage: 0,

      firstCommentList: [],
      commentMedia: [],
      commentPostContent: '',
      showComment: false,
      isShowViewPicture: false,
      viewPictureImg: [],
      imageList: [],
      nowIndex: 0,
      showViewer: true,

      imgList: []
    }
  },
  created () {
    // this.getfirstComment()
    this.nowIndex = this.mediaIndex
    this.imageList = this.postDetail.media.filter(item => item.type == 'photo').map(v => v.url)
    // console.log('............', this.imageList);

    this.imgList = this.postDetail.media.filter(
      (item) => item.type == 'photo'
    )
  },

  mounted () {
    let firstCommentBox = this.$refs.firstComment
    // console.log(111, firstCommentBox);
    firstCommentBox.addEventListener('scroll', this.scrollLoad)
  },

  methods: {
    closeViewPhoto() {
      this.isShowViewPicture = false
      setTimeout(() => {
            let htmlContent=document.getElementsByClassName('el-image-viewer__wrapper')[0]
            // console.log('htmlContent',htmlContent);
            if(htmlContent) htmlContent.style.zIndex='999'
          }, 20);
    },
    // 帖子内容过滤
      postContentFilter(val) {
        let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|:.]*[-A-Z0-9+&@#\/%=~_|])/ig
        val = val.replace(exp, "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>")
        let url = window.location.href.split('#')[0]
        let reg=/(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g
        val = val.replace(reg, `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />")
        return val
      },
    changeImg (val) {
      // console.log('index', val);
      // this.nowIndex = val
      this.imgList = this.postDetail.media.filter(
        (item) => item.type == 'photo');
      let tempImgList = [...this.imgList];
      let temp = [];
      for (let i = 0; i < val; i++) {
        temp.push(tempImgList.shift());
      }
      this.imageList = tempImgList.concat(temp);

    },
    getfirstComment () {
      this.$http
        .getPostCommentInfo({
          user_post_id: this.postDetail.id,
          size: 50,
          page: this.page
        })
        .then((res) => {
          // console.log('det', res);
          this.totalPage = res.data.total
          this.firstCommentList = [...this.firstCommentList, ...res.data.data]
        })
    },

    delSelfPostComment (comment) {
      this.$confirm(
        this.$t('Posts.2f2@this_operation_will_'),
        'Tips', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }
      ).then(() => {
        this.$http
          .delPostComment({
            user_post_comment_id: comment.id
          })
          .then((res) => {
            // 删除顶级列表的某一项
            this.postDetail.firstCommentList = this.postDetail.firstCommentList.filter(
              (some) => some.id !== comment.id
            )
          })
        let subd_n = this.postDetail.comment_total - comment.comment_total - 1
        this.$set(this.postDetail, 'comment_total', subd_n)
      })
    },

    // 滚动加载
    scrollLoad: throttle(function (e) {
      let firstCommentBox = this.$refs.firstComment

      // 已滚动的高度
      let scrollHeight = firstCommentBox.scrollTop // 卷进页面的高度

      // 可视区域
      let clientHeight = firstCommentBox.clientHeight // ul的元素高度

      // 获取元素的高度
      let eleHeight = firstCommentBox.scrollHeight // 元素的滚动高度8000多
      // console.log('eleHeight', eleHeight);
      if (
        eleHeight - scrollHeight - 5 <= clientHeight &&
        this.totalPage > this.page
      ) {
        this.page++
        // this.getfirstComment()
        this.$parent._getFirstCommentList(this.page)
      }
      // 暂时没有更多
      if (this.page == this.totalPage) this.isShow = true
    }),

    commentPost (post) {
      // 评论内容格式化
      let imgTextContent = {}
      imgTextContent.content = this.commentPostContent
      imgTextContent.img = this.commentMedia

      this.$http
        .operationChannelPost({
          type: "add_comment",
          channel_post_id: post.id,
          parent: 0,
          content: JSON.stringify(imgTextContent),
          // commet_parent: '' // 不能省
        })
        .then((res) => {
          if (res.status == 200) {
            post.firstCommentList.unshift(res.data)
            this.commentPostContent = ''
            this.commentMedia = []

            // console.log(post);
            let plus1 = post.comment_total + 1
            this.$set(post, 'comment_total', plus1)
          }
        })
    },

    // 上传
    imgUpload (params) {
      var formData = new FormData()
      formData.append('file', params.file)
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200 && this.commentMedia.length < 9) {
          this.commentMedia.push({
            type: params.file.type.split('/')[0] == 'image' ? 'photo' : 'video',
            url: res.data[0]
          })
        }
      })
    },

    overViewPicture (post) {
      // 評論的圖片
      let media = JSON.parse(post).img
      this.viewPictureImg = media
      this.isShowViewPicture = true
      setTimeout(() => {
            let htmlContent=document.getElementsByClassName('el-image-viewer__wrapper')[0]
            // console.log('htmlContent',htmlContent);
            if(htmlContent) htmlContent.style.zIndex='998'
          }, 20);
    },

    // 以当前点击的预览图为首页
    getSrcList (nowIndex) {
      return this.imageList
        .slice(nowIndex)
        .concat(this.imageList.slice(0, nowIndex));
    },

    // 关闭图片查看器
    closeViewer () {
      this.showViewer = false;
      this.$emit('close')
    },

    // 切换图片 index为图片下标值
    onSwitch (index) {
      this.nowIndex = index
    }
  }

}

</script>


<style lang="scss" scoped>
.comment_and_replay::-webkit-scrollbar {
  display: none;
}

::v-deep .el-carousel__container {
  margin: 200px 0;
}

.post-detail {
  height: 100vh;
  z-index: 999;

  .black-mask {
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */

    > .left-swiper {
      width: calc(100% - 375px);
      height: 100%;
      position: relative;

      #carousel-1 {
        width: 50%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
      }

      > .small-img-box {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;

        > ul {
          display: flex;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.8);

          > li {
            width: 68px;
            height: 69px;
            list-style: none;
            margin-right: 12px;
            border: 2px solid #fff;
            overflow: hidden;

            > img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      > .close_icon {
        position: absolute;
        left: 20px;
        top: 20px;

        > i {
          color: #fff;
          font-size: 48px;
          cursor: pointer;
        }
      }
    }

    > .right-comment {
      width: 375px;
      height: 100%;
      background-color: #fff;
      padding: 32px 20px;
      overflow-y: auto;
      overflow-x: hidden;
      .text-overflow-single-self {
        width: 260px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .user_img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      > .nick_name_box {
        h3 {
          color: #333;
          font-size: 1rem;
        }

        p {
          color: #50b5ff;
        }
      }

      > .post_content {
        margin-top: 20px;
      }

      > .like_comment_share {
        display: flex;
        color: #999;

        li {
          flex: 1;
          font-size: 16px;
          cursor: pointer;

          > i {
            padding: 5px;
            margin-right: 5px;
          }

          &:nth-child(2) {
            text-align: center;
          }

          &:last-child {
            text-align: right;
          }

          &:hover {
            > i {
              background-color: rgb(204, 229, 245);
              border-radius: 50%;
            }

            color: #50b5ff;
          }
        }
      }

      .comment_and_replay > li {
        margin-bottom: 10px;

        .user_avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;

          > img {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        .comment_content {
          width: calc(100% - 35px);

          .comment_main {
            > span:first-child {
              color: #50b5ff;
              cursor: pointer;
            }
          }

          .time_icon {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 12px;
              color: #999;
            }

            > ul {
              display: flex;
              font-size: 18px;

              &:hover {
                & > li:first-child {
                  opacity: 1;
                }
              }

              > li {
                margin-left: 10px;
                padding: 0 5px;
                border-radius: 50%;
                cursor: pointer;

                &:first-child {
                  opacity: 0;
                }

                > i {
                  padding: 5px;
                  border-radius: 50%;

                  &:hover {
                    background-color: rgb(204, 229, 245);
                    color: #50b5ff;
                  }
                }
              }
            }
          }

          .replay_white_box {
            background: #f0f0f0;
            padding: 10px;
            justify-content: flex-end;

            > input {
              width: 100%;
            }

            > div {
              display: flex;
              justify-content: flex-end;
            }
          }
        }

        .second_comment {
          display: flex;
          padding-left: 55px;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 3px;
            height: 60%;
            background-color: #f0f0f0;
            position: absolute;
            top: 50%;
            left: 43px;
            transform: translateY(-50%);
          }

          > span {
            color: #50b5ff;
            cursor: pointer;
          }
        }

        .second_time {
          padding-left: 55px;

          // li hover是显示icon
          &:hover .second_icon {
            opacity: 1;
          }

          span {
            font-size: 12px;
          }

          > ul {
            display: flex;
            opacity: 0;
            font-size: 18px;

            > li {
              margin-left: 15px;
              padding: 0 5px;
              border-radius: 50%;
              cursor: pointer;

              &:hover {
                background-color: rgb(204, 229, 245);
                color: #50b5ff;
                opacity: 1;
              }
            }
          }
        }

        .left_padding_white_box {
          background: #f0f0f0;
          padding: 10px;
          margin-left: 55px;
          justify-content: flex-end;

          > input {
            width: 100%;
          }

          > div {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .postDet_icon {
    i {
      font-size: 18px;
      margin-right: 2px;
      cursor: pointer;
    }
  }

  .media_box {
    display: flex;
    flex-wrap: wrap;

    > .li_pic {
      width: 69px;
      height: 69px;
      margin-right: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      > img,
      > video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; //图片自适应的样式
      }

      .close_img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }
}

.el-image-viewer__wrapper {
  margin-right: 375px;
  margin-bottom: 69px;
}
</style>

<style>
  #modal1___BV_modal_outer_ {
  z-index: 99999 !important;
  }
</style>