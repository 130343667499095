<template>
  <div>
    <iq-card>
      <template v-slot:body>
        <tab-nav
          :pill="true"
          class="d-flex align-items-center p-0 m-0"
          :tabs="true"
        >
          <tab-nav-items
            :active="tabActive == 'Photo'"
            id="pills-photo-you-tab"
            href="#photo"
            ariaControls="pills-photo-you"
            @click.native="checkoutAlu()"
            role="tab"
            :ariaSelected="true"
            :title="$t('MyJCI.71e@photo')"
          />
          <tab-nav-items
            :active="tabActive == 'Video'"
            id="pills-your-photo-tab"
            href="#video"
            @click.native="checkoutVideo()"
            ariaControls="pills-your-photo"
            role="tab"
            :ariaSelected="false"
            :title="$t('MyJCI.e26@video')"
          />
        </tab-nav>

        <!-- 相册图片 -->
        <div class="tab-content" v-if="!is_album_detail">
          <tab-content-item
            :active="tabActive == 'Photo' && videoDetail != 1"
            id="photo"
            aria-labelled-by="pills-photo-you-tab"
          >
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>{{ $t("MyJCI.852@albums") }}</h5>
            </div>

            <Albums
              :albumsList="albumList"
              @editOpenModal="queryEditAlbum"
              :isHideIcon="isHideIcon"
              @toAlbumDet="albumDet"
            >
            </Albums>
            <el-pagination
              layout="prev, pager, next"
              :page-size="per_page"
              :total="total"
              style="text-align: right; margin: 20px 0"
              @current-change="changePage"
              :current-page="current_page"
              hide-on-single-page
            >
            </el-pagination>
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>All photos</h5>
            </div>
            <Photo
              :photoListData="photoList"
              :showMoveModalFunc="showMoveModal"
              :isHideIcon="isHideIcon"
              :isShowNum="isShowNum"
            />
            <el-pagination
              layout="prev, pager, next"
              :page-size="photo_page"
              :total="photoTotal"
              style="text-align: right; margin: 20px 0"
              @current-change="changePhoto"
              :current-page="current_photo"
              hide-on-single-page
            >
            </el-pagination>
          </tab-content-item>
          <tab-content-item
            :active="tabActive == 'Video' && videoDetail == 1"
            id="video"
            aria-labelled-by="pills-your-photo-tab"
            class="p-2"
          >
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>All Videos</h5>
            </div>
            <Video :isHideIcon="isHideIcon" :videoList="videoesList" />
            <div
              class="bottomof"
              v-if="totalVid > 12"
              style="text-align: right; margin-top: 30px; margin-bottom: 10px"
            >
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="12"
                @current-change="current_change"
                :current-page.sync="currentVid"
                :total="totalVid"
              >
              </el-pagination>
            </div>
          </tab-content-item>
        </div>

        <!-- 相册详情 -->
        <div
          v-else
          class="album_detail"
          v-loading="loading"
          element-loading-text="uploading"
        >
          <div class="d-flex justify-content-between">
            <ul class="album_path p-0 mt-3">
              <li
                style="cursor: pointer"
                @click="(is_album_detail = false), (videoDetail = 0)"
              >
                {{ $t("MyJCI.03f@album") }}
              </li>
              <li>></li>
              <li>{{ $t("MyJCI.e4d@landscape") }}</li>
            </ul>
          </div>
          <DetPhoto
            :photoListData="albumDetailPhoto"
            :showMoveModalFunc="showMoveModal"
            :isHideIcon="isHideIcon"
            :isShowNum="isShowNum"
          />
          <div
            class="bottomof"
            style="text-align: right; margin-top: 30px; margin-bottom: 10px"
          >
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="12"
              @current-change="currentAluDet"
              :current-page.sync="currentAlu"
              :total="totalAlu"
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </iq-card>
  </div>
</template>

<script>
import Albums from "../../../Profile/components/Media/components/Album";
import Photo from "../../../Profile/components/Media/components/Photo";
import DetPhoto from "../../../Profile/components/Media/components/Photo";
import Video from "../../../Profile/components/Media/components/Video";
export default {
  name: "Media",
  components: { Albums, Photo, DetPhoto, Video },
  // props: ["photoList"],
  data() {
    return {
      is_album_detail: false,
      // addAlbum
      addAlbumData: {
        title: "",
        sort: "",
        // introduce: "",
      },
      albumList: [],
      addUpdateFlag: 0,
      albumInfo: {},

      photoList: [],

      // photo
      LandScape: "LandScape",
      albumDetailPhoto: [],

      // MOVE
      selected: 0,
      movePhotoId: 0,
      allAlbumList: [], //相册列表（名称/id）
      // 相册分页
      per_page: 4, //相册默认展示数量
      total: 0, //相册总数
      current_page: 1, //相册当前页

      photo_page: 12, //相片默认数量
      photoTotal: 0, //相片总数
      current_photo: 1, //相片当前页

      videoesList: [],
      totalVid: 0,
      currentVid: 1,
      album_id: "",
      loading: false,
      currentAlu: 1,
      totalAlu: 0,
      videoDetail: 0, //是否从详情跳至video
      isHideIcon: false,
      isShowNum: false,

      tabActive: "Photo",
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.addAlbumData = {};
    });
  },
  methods: {
    getMedia() {
      this.videoDetail = "0";
      this.tabActive = "Photo";
      this.is_album_detail = false;
      this.getAlbum();
      this.getAllPhoto();
    },
    checkoutAlu() {
      this.tabActive = "Photo";
      this.currentVid = 1;
      this.getAlbum();
      this.getAllPhoto();
    },
    checkoutVideo() {
      this.tabActive = "Video";
      this.current_photo = 1;
      this.current_page = 1;
      this.is_album_detail = false;
      this.videoDetail = 1;
      this.getVideo();
    },

    changePhoto(page) {
      this.current_photo = page;
      this.getAllPhoto();
    },
    changePage(page) {
      this.current_page = page;
      this.getAlbum();
    },

    current_change(item) {
      this.currentVid = item;
      this.getVideo();
    },

    queryEditAlbum(val) {
      this.$bvModal.show("album");
      this.addAlbumData = val;
      this.addUpdateFlag = 1;
      this.albumInfo = val;
    },

    // 获取该相片的所有照片详情
    queryAlbumDet(id) {
      this.$bvModal.show("album");
      this.addUpdateFlag = 1;
    },

    allbumList() {
      this.$http
        .allAlbumList({
          model_type: 2,
          model_id: this.$route.query.channel_id,
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res);
            this.allAlbumList = res.data;
          }
        });
    },

    getAlbum() {
      this.$http
        .getAllAlbums({
          model_type: 2,
          model_id: this.$route.query.channel_id,
          page: this.current_page,
        })
        .then((res) => {
          if (res.status == 200) {
            this.albumList = res.data.data;
            this.total = res.data.total;
          }
        });
    },

    // 获取视频
    getVideo() {
      this.videoesList = [];
      this.$http
        .getVideo({
          model_id: this.$route.query.channel_id,
          model_type: 2,
          page: this.currentVid,
        })
        .then((res) => {
          if (res.status == 200) {
            // this.videoesList = res.data.data;
            res.data.data.forEach((item) => {
              this.videoesList.push({ ...item, isPlay: 0 });
            });
            this.totalVid = res.data.total;
          }
        });
    },
    // 获取相片
    getAllPhoto() {
      this.$http
        .getAllPhoto({
          model_type: 2,
          model_id: this.$route.query.channel_id,
          per_page: this.photo_page,
          page: this.current_photo,
        })
        .then((res) => {
          if (res.status == 200) {
            this.photoList = res.data.data;
            this.photoTotal = res.data.total;
          }
        });
    },
    // move
    showMoveModal(id) {
      this.$bvModal.show("moveTo");
      this.allbumList();
      this.movePhotoId = id;
    },
    currentAluDet(item) {
      this.currentAlu = item;
      this.albumDet(this.album_id);
    },
    albumDet(id) {
      this.loading = true;
      this.album_id = id;
      this.is_album_detail = true;
      this.$http
        .albumPhotos({
          album_id: id,
          page: this.currentAlu,
        })
        .then((res) => {
          this.LandScape = res.data.title;
          this.totalAlu = res.data.photos.total;
          this.albumDetailPhoto = res.data.photos.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-content {
  margin-top: 100px;
}

::v-deep .nav-tabs .nav-link.active {
  border: none;
}

.album_detail {
  .album_path {
    display: flex;
    font-size: 16px;

    > li:first-child {
      color: #62bdff;
    }

    > li:nth-child(2) {
      padding: 0 5px;
    }
  }
}
</style>
