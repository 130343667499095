<template>
  <div>
    <b-row>
      <b-col sm="12">
        <!-- user info -->
        <iq-card class="iq-card">
          <div class="cover-container">
            <img
              class="img-fluid"
              v-show="!channelDet.background"
              style="width: 100%;  object-fit: cover"
              src="../../../assets/images/page-img/organization-bg.jpg"
              alt=""
            />
            <img
              v-show="channelDet.background"
              style="width: 100%; object-fit: cover"
              :src="channelDet.background"
              alt="profile-bg"
              class="img-fluid"
            />
          </div>

          <b-row style="min-height: 150px">
            <!-- avatar -->
            <b-col sm="12" md="12" lg="12">
              <div class="user-info">
                <div class="avatar-box avatar-box-kong">
                  <!-- <img v-if="channelDet.image" :src="channelDet.image" /> -->
                  <el-image
                    :src="channelDet.image"
                    fit="cover"
                    style="width: 100%; height: 100%; border-radius: 50%"
                  >
                    <div slot="error" class="image-slot">
                      <img
                        src="@/assets/images/public/circle_jci.png"
                      />
                    </div>
                  </el-image>
                </div>
                <!-- <div class="avatar-box avatar-box-kong" v-show="!channelDet.image">
                  <img class="my_long" src="../../../assets/images/public/circle_jci.png" />
                </div> -->

                <div class="flow-data">
                  <h3>{{ channelDet.title }}</h3>
                  <ul class="p-0 d-flex flex-wrap mb-0">
                    <li>{{ channelDet.type_desc }} Organization <span v-if="channelDet.country.en_name||channelDet.follow_sum > 0">，</span></li>
                    <li v-if="channelDet.country">
                      {{ channelDet.country.en_name }}
                      <span v-if="channelDet.follow_sum > 0">，</span>
                    </li>
                    <li v-if="channelDet.follow_sum > 0">
                      {{ channelDet.follow_sum }}
                      <span v-if="channelDet.follow_sum == 1">member</span>
                      <span v-else>members</span> follow page.
                    </li>
                  </ul>
                  <p>
                    <a :href="channelDet.website" target="_blank">{{ channelDet.website }}</a> 
                  </p>
                </div>
              </div>
            </b-col>
            <!-- button -->
            <b-col
              class="d-flex justify-content-between align-items-center"
              sm="12"
              md="12"
              lg="12"
            >
              <ul class="btn-box list-inline pt-3 pr-3">
                <li class="text-center mr-2">
                  <b-button
                    type="submit"
                    v-if="channelDet.is_follow"
                    @click="
                      channelFollow(channelDet.channel_id, 2, channelDet.id)
                    "
                  >
                    <i class="ri-add-line m-0"></i
                    >{{ $t("Channels.265@followed") }}
                  </b-button>
                  <b-button
                    v-else
                    type="submit"
                    style="background-color: #50b5ff; border-color: #50b5ff"
                    @click="
                      channelFollow(channelDet.channel_id, 1, channelDet.id)
                    "
                  >
                    <i class="ri-add-line m-0"></i>
                    {{ $t("Channels.26d@follow") }}
                  </b-button>
                  <!--  -->
                  <b-button
                    v-if="showCreatPost"
                    @click="createOrganizationGroup"
                    style="
                      background-color: #50b5ff;
                      border-color: #50b5ff;
                      margin-left: 5px;
                    "
                  >
                    Group Chat
                  </b-button>
                </li>
                <li class="text-center">
                  <b-button
                    v-if="channelDet.is_tipping == 2"
                    variant="outline-warning"
                    @click="openTipsModal"
                    >Reward</b-button
                  >
                </li>
              </ul>
            </b-col>
          </b-row>
        </iq-card>

        <!-- toggle -->
        <iq-card class="iq-card">
          <div class="iq-card-body p-0">
            <div class="user-tabing profile-feed-items">
              <tab-nav
                class="d-flex tabsWidthScroll align-items-center justify-content-between p-0 m-0"
                :tabs="true"
              >
                <tab-nav-items
                  v-if="tab.includes('timeline')"
                  class="col p-0"
                  :active="tabActive == 'timeline'"
                  id="pills-feed-tab"
                  @click.native="(tabActive = 'timeline'), getTimeLise()"
                  href="#timeline"
                  :title="$t('Channels.1b6@timeline')"
                />
                <tab-nav-items
                  v-if="tab.includes('about')"
                  :active="tabActive == 'about'"
                  class="col p-0"
                  id="pills-activity-tab"
                  href="#about"
                  :title="$t('Channels.de9@about')"
                  @click.native="(tabActive = 'about'), channelAbout()"
                />
                <!-- <tab-nav-items
                  v-if="tab.includes('project')"
                  class="col p-0"
                  :active="false"
                  id="pills-activity-tab"
                  href="#project"
                  :title="$t('Channels.463@project')"
                /> -->
                <tab-nav-items
                  v-if="tab.includes('media')"
                  :active="tabActive == 'media'"
                  class="col p-0"
                  id="pills-friend-tab"
                  @click.native="(tabActive = 'media'), getMedia()"
                  href="#Media"
                  :title="$t('Channels.6f8@media')"
                />
                <tab-nav-items
                  v-if="tab.includes('document')"
                  :active="tabActive == 'document'"
                  class="col p-0"
                  id="pills-profile-tab"
                  href="#Documents"
                  :title="$t('Channels.084@document')"
                  @click.native="(tabActive = 'document'), channelFile()"
                />
                <!-- <tab-nav-items
                  v-if="tab.includes('shop')"
                  class="col p-0"
                  :active="false"
                  id="pills-profile-tab"
                  href="#Shop"
                  :title="$t('Channels.e1d@shop')"
                /> -->
              </tab-nav>
            </div>
          </div>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <tab-content class="tab-content">
          <tab-content-item
            :active="tabActive == 'timeline'"
            id="timeline"
            aria-labelled-by="pills-feed-tab"
          >
            <Timeline
              :channelDet="channelDet"
              :showCreatPost="showCreatPost"
              ref="timeLine"
              @checkAbout="(tabActive = 'about'), channelAbout()"
            />
          </tab-content-item>
          <tab-content-item
            :active="tabActive == 'about'"
            id="about"
            aria-labelled-by="pills-activity-tab"
          >
            <div class="iq-card">
              <div class="iq-card-body">
                <!-- :cocList="cocList"
                  
                  :bod_list="bod_list"
                  :senator="senator" -->
                <About
                  ref="about"
                  :type="type"
                  :partners="partners"
                  :sponsorLarge="sponsorLarge"
                  :sponsorSmall="sponsorSmall"
                  :tab="aboutTab"
                  :intro="intro"
                  :channelDet="channelDet"
                  :showEditOrg="showEditOrg"
                />
              </div>
            </div>
          </tab-content-item>
          <!-- <tab-content-item
            :active="false"
            id="project"
            aria-labelled-by="pills-activity-tab"
          >
            <div class="iq-card">
              <div class="iq-card-body">
                <Project />
              </div>
            </div>
          </tab-content-item> -->
          <tab-content-item
            :active="tabActive == 'media'"
            id="Media"
            aria-labelled-by="pills-friend-tab"
          >
            <!-- <Media
              :photoList="photoList"
              :videoListData="videoList"
              :photoTotal="photoTotal"
              :videoTotal="videoTotal"
              @pageChange="changePage"
            /> -->
            <Media :photoList="photoList" ref="albums" />
          </tab-content-item>
          <tab-content-item
            :active="tabActive == 'document'"
            id="Documents"
            aria-labelled-by="pills-profile-tab"
            @click="channelFile"
          >
            <Documents :fileList.sync="fileList" />
          </tab-content-item>
          <!-- <tab-content-item
            :active="false"
            id="Shop"
            aria-labelled-by="pills-profile-tab"
          >
            <Shop />
          </tab-content-item> -->
        </tab-content>
      </b-col>
    </b-row>

    <b-modal id="tips" title="Support" hide-footer>
      <div class="common-part amount-box">
        <ul class="btn-select m-0 p-0 three-btn">
          <li
            class="self-r"
            :class="{ outlineBlue: activeLi == 1 }"
            @click="activeLi = 1"
          >
            $100
            <input
              type="radio"
              v-model="selected"
              name="some-radios"
              value="100"
              class="cursor-pointer"
            />
          </li>
          <li
            class="self-r"
            :class="{ outlineBlue: activeLi == 2 }"
            @click="activeLi = 2"
          >
            $200
            <input
              type="radio"
              v-model="selected"
              name="some-radios"
              value="200"
              class="cursor-pointer"
            />
          </li>
          <li
            class="self-r"
            :class="{ outlineBlue: activeLi == 3 }"
            @click="activeLi = 3"
          >
            $300
            <input
              type="radio"
              v-model="selected"
              name="some-radios"
              value="300"
              class="cursor-pointer"
            />
          </li>
          <li :class="{ outlineBlue: activeLi == 4 }" @click="activeLi = 4">
            Other
            <input
              type="radio"
              v-model="selected"
              name="some-radios"
              value=""
              class="cursor-pointer"
            />
          </li>
        </ul>
        <div class="d-flex align-item-center self_pre_input mt-4">
          <span>USD</span>
          <el-input
            :disabled="activeLi != 4"
            v-model="selected"
            @input="
              (value) => {
                limitInput(value);
              }
            "
          />
        </div>
        <div class="d-flex justify-content-between">
          <p class="mt-2 currentBalance">
            {{ $t("Channels.e07@you_have") }}
            <span>{{ channelDet.user_diamond }}</span>
            {{ $t("Channels.80a@carats_left") }}
          </p>
          <p @click="$router.push('/wallet')" class="mt-2 textYellow">
            {{ $t("Channels.9ac@insufficient_balance") }}
          </p>
        </div>

        <div class="d-flex align-item-center justify-content-center mt-3">
          <el-button
            @click="$bvModal.hide('tips')"
            size="small"
            type="danger"
            plain
            >cancel</el-button
          >
          <el-button @click="rewardFunc" size="small" type="primary"
            >confirm</el-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Timeline from "./components/Timeline";
import About from "./components/About";
import Media from "./components/Media";
import Documents from "./components/Documents";
import Shop from "./components/Shop";
import Project from "./components/Project";

export default {
  data() {
    return {
      channelDet: {},
      activeLi: 0,
      selected: "",
      // 收款人id
      payee_id: 0,
      fileList: [],
      photoList: [],
      videoList: [],
      photoTotal: 0,
      videoTotal: 0,
      photoPage: 1,
      tab: [],

      // about
      type: 0,
      aboutTab: [],
      intro: {},
      cocList: [],
      partners: [],
      sponsorLarge: [],
      sponsorSmall: [],
      tabActive: "timeline",

      showCreatPost: false,
      showEditOrg: false,
    };
  },

  components: {
    Timeline,
    About,
    Media,
    Documents,
    Shop,
    Project,
  },

  created() {
    this.channelDetail();
    this.userAuth();
  },
  methods: {
    limitInput(value, item) {
      if (/[^0-9]/g.test(value)) {
        this.$message.warning("Only numbers can be entered");
      }
      this.selected = value.replace(/[^0-9]/g, "");
    },
    getMedia() {
      this.$refs.albums.getMedia();
    },
    getTimeLise() {
      this.$refs.timeLine.getCreat();
    },
    // 打开tips弹框
    openTipsModal() {
      this.$http
        .getTipPayee({
          model_type: this.channelDet.type,
          model_id: this.channelDet.channel_id,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.payee_id = res.data.payee_id;
            this.$bvModal.show("tips");
          }
        });
    },

    rewardFunc() {
      this.$http
        .getUserTips({
          model_type: this.channelDet.type,
          model_id: this.channelDet.channel_id,
          diamond: this.selected,
          payee_id: this.payee_id,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.$bvModal.hide("tips");
            this.channelDetail();
            this.selected = "";
            this.$message.success(res.message);
          }
        });
    },

    // 获取ChannelDetail
    async channelDetail() {
      let res = await this.$http.channelDetail({
        channel_id: this.$route.query.channel_id,
      });
      this.channelDet = res.data;
      Object.keys(res.data.page_tab).forEach((key) => {
        if (res["data"]["page_tab"][key]) {
          this.tab.push(key);
        }
      });
      // about
      this.$store.commit("getAbout", res.data.about);
    },
    userAuth() {
      this.$http
        .userAuth({
          organization_id: this.$route.query.id,
          user_id: JSON.parse(localStorage.getItem("userInfo")).user_id,
        })
        .then((res) => {
          if (res.status == 200) {
            this.showCreatPost = res.data.is_member;
            this.showEditOrg = res.data.is_bod;
          }
        });
    },
    channelFollow(channel_id, type, organization_id) {
      this.$http
        .channelFollow({
          organization_id,
          channel_id,
          type,
        })
        .then((res) => {
          if (res.status == 200) {
            this.channelDetail();
          }
        });
    },

    // 获取频道文件
    channelFile() {
      this.$http
        .channelFile({
          channel_id: this.$route.query.channel_id,
        })
        .then((res) => {
          if (res.status == 200) {
            this.fileList = res.data;
          }
        });
    },
    // 获取ChannelPhotoList
    async getChannelMedia() {
      let photo = await this.$http.getAllPhoto({
        model_type: 2,
        model_id: this.$route.query.channel_id,
        per_page: 12,
        page: this.photoPage,
      });
      this.photoList = photo.data.data;
      this.photoTotal = photo.data.total;
      let video = await this.$http.getAllVideos({
        model_type: 2,
        model_id: this.$route.query.channel_id,
        per_page: 12,
        page: this.photoPage,
      });
      // this.videoList = video.data.data;
      // this.videoTotal = video.data.total;
      this.$refs.albums.getAlbum();
    },
    changePage(val) {
      this.photoPage = val;
      this.getChannelMedia();
    },
    // getAbout
    channelAbout() {
      this.$refs.about.checkTab();
      this.$http
        .channelAbout({
          channel_id: this.$route.query.channel_id,
        })
        .then((res) => {
          this.type = res.data.type;
          // tab
          let about_teb = res.data.about_teb;
          Object.keys(about_teb).forEach((key) => {
            if (about_teb[key]) {
              this.aboutTab.push(key);
            }
          });
          this.intro = res.data.introduction;
          // this.bod_list = res.data.bod_list;
          // this.senator = res.data.senator;
          // this.cocList = res.data.coc_list;
          // let { sponsors, partners } = res.data.partnerships;
          // this.sponsorLarge = sponsors.filter((item) => item.crade == 1);
          // this.sponsorSmall = sponsors.filter((item) => item.crade == 2);
          // this.partners = partners;
        });
    },
    // 组织成员加入群
    createOrganizationGroup() {
      this.$http
        .createOrganizationGroup({
          organization_id: this.$route.query.id,
        })
        .then((res) => {
          // console.log(res)
          if (res.status == 200) {
            this.$router.push("/chat");
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  ::v-deep #tips .modal-dialog {
    max-width: 700px !important;
  }
  .cover-container{
    img{
      height: 300px;
    }
  }
  .common-part {
    padding: 20px;

    > h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }

    > .btn-select {
      display: flex;
      margin-top: 26px;

      .outlineBule {
        border-color: #50b5ff;
        background-color: #50b5ff;
      }

      > li {
        width: 315px;
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        position: relative;
        cursor: pointer;

        &.self-r {
          margin-right: 20px;
        }

        > input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .choosed-result {
      padding-top: 30px;
    }
  }
    .user-info{
      .avatar-box {
        width: 140px;
        height: 140px;
        top: -60px;
        left: 30px;
      }
      .flow-data {
        left: 190px;
      }
    }
}
::v-deep .el-select {
  width: 100px;
}
.user-info {
  position: relative;
  height: 85px;

  .avatar-box {
    border-radius: 50%;
    position: absolute;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .my_long {
      width: 78px;
      height: 60px;
    }
  }

  .avatar-box-kong {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eff0f2;
  }

  .flow-data {
    position: absolute;
    top: 0;
  }
  @media (max-width: 576px) {
    .flow-data{
      font-size: 12px;
      ul>li{
        overflow: hidden;
        max-width:210px;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // display: -webkit-box;
      }
      p{
        overflow: hidden;
        max-width:210px;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // display: -webkit-box;
      }
    }
  }
}
.self_pre_input {
  > span {
    display: block;
    width: 122px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #f8f8f8;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
  }
}

.btn-box {
  display: flex;
  margin-left: 30px;

  .btn {
    width: 130px;
  }
}

.btn-select {
  display: flex;
  margin-top: 26px;

  .self-btn {
    width: 100%;
    height: 45px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    text-align: center;
    line-height: 45px;
    position: relative;
    margin: 5px 0;
    margin-right: 20px;

    > input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .nav-tabs .nav-item a{
    font-size: 14px;
    padding: 10px 8px !important;
  }
  .common-part {
    padding: 20px;

    > h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }

    > .btn-select {
      margin-top: 26px;

      > li {
        width: 100%;
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        position: relative;
        margin: 5px 0;

        &.self-r {
          margin-right: 20px;
        }

        > input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    > .three-btn {
      display: flex;
      flex-wrap: wrap;

      > li {
        width: calc(33.3333% - 20px);
      }
    }

    .choosed-result {
      padding-top: 30px;
    }
  }
  .btn-box {
    margin-top: 30px;
  }
  .user-info{
    .avatar-box {
      width: 70px;
      height: 70px;
      top: -35px;
      left: 15px;
    }
  }
  .flow-data{
    left: 100px;
  }
}
.textYellow {
  text-align: right;
  color: #ffba68;
  line-height: 26px;
  cursor: pointer;
}

.currentBalance {
  font-family: montserrat;
  color: #999999;

  span {
    color: #50b5ff;
  }
}
</style>
