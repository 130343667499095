<template>
  <section>
    <el-row :gutter="10">
      <el-col class="pcTaps" :span="8">
        <tab-nav
          :pills="true"
          id="tab"
          class="nav nav-pills basic-info-items list-inline d-block p-0 m-0"
        >
          <!-- v-if="tab.includes('introduction')" -->
          <tab-nav-items
            :active="tabNav == 'Intro'"
            @click.native="tabNav = 'Intro'"
            id="pills-contact-info"
            href="#Introduction"
            :title="$t('Channels.f39@introduction')"
          />
          <!-- v-if="tab.includes('bod_list')" -->
          <tab-nav-items
            :active="tabNav == 'Membe'"
            id="pills-family-info"
            @click.native="checkMember()"
            href="#member"
            title="Members"
          />
          <!-- v-if="tab.includes('senator')" -->
          <tab-nav-items
            :active="tabNav == 'Senat'"
            id="pills-family-info"
            @click.native="checkSenator()"
            href="#senator"
            title="Senators"
          />
          <tab-nav-items
            v-if="tab.includes('coc_list')"
            :active="tabNav == 'Coc'"
            @click.native="tabNav = 'Coc'"
            id="pills-family-info"
            href="#coc"
            title="COC"
          />
          <tab-nav-items
            v-if="tab.includes('partnerships')"
            :active="tabNav == 'Part'"
            @click.native="tabNav = 'Part'"
            id="pills-about-info"
            href="#Partner"
            title="Partnerships"
          />
          <!-- v-if="tab.includes('twinning_list')" -->
          <tab-nav-items
            :active="tabNav == 'Twinn'"
            @click.native="(tabNav = 'Twinn'), channelTwinning()"
            id="pills-about-info"
            href="#Twinning"
            title="Twinning"
          />
          <tab-nav-items
            v-if="showEditOrg"
            :active="tabNav == 'Organ'"
            @click.native="tabNav = 'Organ'"
            id="pills-about-info"
            href="#EditOrg"
            title="Edit Organization"
          />
        </tab-nav>
      </el-col>
      <el-col class="phoneTaps" :span="24">
        <tab-nav
          :pills="true"
          id="tab"
          class="d-flex tabsWidthScroll align-items-center justify-content-between p-0 m-0"
        >
          <!-- v-if="tab.includes('introduction')" -->
          <tab-nav-items
            :active="tabNav == 'Intro'"
            @click.native="tabNav = 'Intro'"
            id="pills-contact-info"
            href="#Introduction"
            :title="$t('Channels.f39@introduction')"
          />
          <!-- v-if="tab.includes('bod_list')" -->
          <tab-nav-items
            :active="tabNav == 'Membe'"
            id="pills-family-info"
            @click.native="checkMember()"
            href="#member"
            title="Members"
          />
          <!-- v-if="tab.includes('senator')" -->
          <tab-nav-items
            :active="tabNav == 'Senat'"
            id="pills-family-info"
            @click.native="checkSenator()"
            href="#senator"
            title="Senators"
          />
          <tab-nav-items
            v-if="tab.includes('coc_list')"
            :active="tabNav == 'Coc'"
            @click.native="tabNav = 'Coc'"
            id="pills-family-info"
            href="#coc"
            title="COC"
          />
          <tab-nav-items
            v-if="tab.includes('partnerships')"
            :active="tabNav == 'Part'"
            @click.native="tabNav = 'Part'"
            id="pills-about-info"
            href="#Partner"
            title="Partnerships"
          />
          <!-- v-if="tab.includes('twinning_list')" -->
          <tab-nav-items
            :active="tabNav == 'Twinn'"
            @click.native="(tabNav = 'Twinn'), channelTwinning()"
            id="pills-about-info"
            href="#Twinning"
            title="Twinning"
          />
          <tab-nav-items
            v-if="showEditOrg"
            :active="tabNav == 'Organ'"
            @click.native="tabNav = 'Organ'"
            id="pills-about-info"
            href="#EditOrg"
            title="Edit Organization"
          />
        </tab-nav>
      </el-col>
      <el-col :xs="24" :sm="16" style="padding-left:0">
        <div class="tab-content">
          <!-- introduse -->
          <tab-content-item
            :active="tabNav == 'Intro'"
            id="Introduction"
            aria-labelled-by="pills-contact-info"
          >
            <h4>{{ $t("Channels.f39@introduction") }}</h4>
            <hr />
            <div class="introDet">
              <p class="ml-2" v-html="intro.introduction"></p>
                <el-row>
                  <el-col :xs="12" :sm="8"><h6>Year of Establishment</h6></el-col>
                  <el-col :xs="12" :sm="16">
                    <p class="mb-0 longText" :title="intro.year">
                      {{ intro.year || "-" }}
                    </p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :xs="12" :sm="8"><h6>Official Language</h6></el-col>
                  <el-col :xs="12" :sm="16">
                    <p class="mb-0 longText" :title="intro.language">
                      {{ intro.language || "-" }}
                    </p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :xs="12" :sm="8"><h6>Member Gender</h6></el-col>
                  <el-col :xs="12" :sm="16">
                    <p class="mb-0 longText" :title="intro.gender">
                      <span v-if="!intro.gender">-</span>
                      <span v-else>{{ intro.gender | filterGender }}</span>
                    </p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :xs="12" :sm="8"><h6>Contact Email</h6></el-col>
                  <el-col :xs="12" :sm="16"> 
                    <p class="mb-0 longText" :title="intro.email">
                      {{ intro.email || "-" }}
                    </p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :xs="12" :sm="8"><h6>Contact Phone Number</h6></el-col>
                  <el-col :xs="12" :sm="16"> 
                    <p class="mb-0 longText" :title="intro.mobile">
                      {{ intro.mobile || "-" }}
                    </p>
                  </el-col>
                </el-row>
            </div>
            <!-- 项目 -->
            <div
              v-if="type == 1 || type == 2 || type == 3 || type == 4"
              class="row"
            >
              <!-- <div class="col-3">
                <h6 class="mb-1">{{ $t("Channels.26d@follow") }}</h6>
              </div>
              <div class="col-9">
                <h6 class="mb-1">{{ intro.country }}</h6>
              </div>
              <div class="col-3">
                <h6 class="mb-1">{{ $t("Channels.7d7@event_link") }}</h6>
              </div>
              <div class="col-9">
                <router-link
                  v-if="intro.operation_type == 4"
                  :to="{
                    path: '/event-detail',
                    query: { id: intro.link_id },
                  }"
                >
                  {{ $t("Channels.549@click_here") }}</router-link
                >
                <p v-else class="mb-1">Has been removed</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">{{ $t("Channels.bce@event_peroid") }}</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.time }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Time Zone</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.time_zone }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">{{ $t("Channels.bc1@language") }}</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">
                  {{ intro.language ? intro.language.join("/") : "-" }}
                </p>
              </div> -->

              <div class="col-3">
                <h6 class="mb-1">Time</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.time }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Region</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.country }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Language</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">
                  {{ intro.language ? intro.language.join("/") : "-" }}
                </p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Link</h6>
              </div>
              <div class="col-9">
                <router-link
                  v-if="intro.operation_type == 1"
                  :to="{
                    path: '/event-detail',
                    query: { id: intro.link_id, type: 2 }
                  }"
                >
                  {{ $t("Channels.549@click_here") }}</router-link
                >
                <p v-else class="mb-1">Has been removed</p>
              </div>
            </div>

            <!-- 特殊身份 -->
            <div
              class="row"
              v-if="type == 11 || type == 12 || type == 13 || type == 14"
            >
              <div class="col-3">
                <h6 class="mb-1">Business Hours</h6>
              </div>
              <div class="col-9">
                <h6 class="mb-1">
                  {{ intro.business_hours_start }}-{{
                    intro.business_hours_end
                  }}
                </h6>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Email</h6>
              </div>
              <div class="col-9">
                <h6>{{ intro.email }}</h6>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Mobile</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.phone }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Media</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.link }}</p>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Address</h6>
              </div>
              <div class="col-9">
                <p class="mb-1">{{ intro.detailed_address }}</p>
              </div>
            </div>

            <!-- 组织类型 -->
            <div class="row" v-if="type >= 21 && type <= 25">
              <div class="col-3">
                <h6 class="mb-1">Year Of Establishment</h6>
              </div>
              <div class="col-9 pl-5">
                <h6 class="mb-1">
                  {{ intro.year ? intro.year : "-" }}
                </h6>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Official Language</h6>
              </div>
              <div class="col-9 pl-5">
                <h6>{{ intro.language }}</h6>
              </div>
              <div class="col-3">
                <h6 class="mb-1">Member Gender</h6>
              </div>
              <div class="col-9 pl-5">
                <p class="mb-1">{{ intro.gender | filterGender }}</p>
              </div>
            </div>
          </tab-content-item>

          <!-- bod -->
          <tab-content-item
            :active="tabNav == 'Membe'"
            id="member"
            aria-labelled-by="pills-family-info"
          >
            <div class="searchInt">
              <el-input
                v-model="searchMem"
                clearable
                @change="searchBodMem()"
                placeholder="Search Member"
              ></el-input>
            </div>
            <div v-if="bod_list.length > 0" class="mb-5">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <h4>BOD Members</h4>
                <!-- <el-select v-model="selectYear">
                <el-option :value="item" v-for="(item, index) in bodYearList" :key="index">{{ item }}</el-option>
              </el-select> -->
              </div>
              <hr />
              <!-- <el-row class="suggestions-lists m-0 p-0">
              <el-col :span="12" v-for="(item, index) in bodListData.filter(
                  (item) => item.year == selectYear
                )" :key="index">
                <div class="d-flex mb-4 align-items-center">
                  <div class="user-img img-fluid">
                    <img :src="item.avatar" class="rounded-circle avatar-40" />
                  </div>
                  <div class="media-support-info ml-3">
                    <h6>{{ item.nickname }}</h6>
                    <p class="mb-0">{{ item.Organization_name }}</p>
                  </div>
                </div>
              </el-col>
            </el-row> -->
              <el-row class="suggestions-lists m-0 p-0 bod_container">
                <el-col
                  :span="12"
                  v-for="(item, index) in bod_list"
                  :key="index"
                >
                  <div
                    class="d-flex mb-4 align-items-center"
                    @click="
                      $router.push({
                        path: '/profile',
                        query: { user_id: item.user_id }
                      })
                    "
                  >
                    <div class="user-img img-fluid" style="cursor: pointer">
                      <img
                        :src="item.avatar"
                        class="rounded-circle avatar-40"
                      />
                    </div>
                    <div
                      class="media-support-info ml-3"
                      style="cursor: pointer"
                    >
                      <h6>{{ item.nick_name }}</h6>
                      <p class="mb-0">
                        {{ item.job_title_name ? item.job_title_name : "-" }}
                      </p>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="d-flex justify-content-end container">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="bodPage.total"
                  :pager-count="3"
                  :page-size="bodPage.per_page"
                  :current-page="bodPage.page"
                  @current-change="handleCurrentBod"
                  hide-on-single-page
                >
                </el-pagination>
              </div>
            </div>
            <div v-if="member_list.length > 0">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <h4>Members</h4>
              </div>
              <hr />
              <el-row class="suggestions-lists m-0 p-0 bod_container">
                <el-col
                  :xs="12" :sm="12"
                  v-for="(item, index) in member_list"
                  :key="index"
                >
                  <div
                    class="d-flex mb-4 align-items-center"
                    @click="
                      $router.push({
                        path: '/profile',
                        query: { user_id: item.user_id }
                      })
                    "
                  >
                    <div class="user-img img-fluid" style="cursor: pointer">
                      <img
                        :src="item.avatar"
                        class="rounded-circle avatar-40"
                      />
                    </div>
                    <div
                      class="media-support-info ml-3"
                      style="cursor: pointer"
                    >
                      <h6>{{ item.nick_name }}</h6>
                      <!-- <p class="mb-0">{{ item.job_name ? item.job_name : "-" }}</p> -->
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="d-flex justify-content-end container">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="memberPage.total"
                  :page-size="memberPage.per_page"
                  :current-page="memberPage.page"
                  :pager-count="3"
                  @current-change="handleCurrentMem"
                  hide-on-single-page
                >
                </el-pagination>
              </div>
            </div>
          </tab-content-item>

          <!-- senator -->
          <tab-content-item
            :active="tabNav == 'Senat'"
            id="senator"
            aria-labelled-by="pills-family-info"
          >
            <div class="searchInt">
              <el-input
                v-model="searchSen"
                clearable
                @change="searchSenator()"
                placeholder="Search Member"
              ></el-input>
            </div>
            <h4>Senators</h4>
            <hr />
            <el-row class="suggestions-lists m-0 p-0" v-if="senator.length > 0">
              <el-col :xs="12" :sm="12" v-for="(user, index) in senator" :key="index">
                <div
                  class="d-flex mb-4 align-items-center"
                  @click="
                    $router.push({
                      path: '/profile',
                      query: { user_id: user.user_id }
                    })
                  "
                >
                  <div class="user-img img-fluid" style="cursor: pointer">
                    <img :src="user.avatar" class="rounded-circle avatar-40" />
                  </div>
                  <div class="media-support-info ml-3" style="cursor: pointer">
                    <h6>{{ user.nick_name }}</h6>
                    <p class="mb-0">{{ user.member_number }}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <b-row
              v-else
              class="d-flex justify-content-center"
              style="margin-top: 60px"
            >
              <!-- <img style width:250px; src="@/assets/images/group/empty1.png" alt="" /> -->
            </b-row>
            <div class="d-flex justify-content-end container">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="seaPage.total"
                :pager-count="3"
                :page-size="seaPage.per_page"
                :current-page="seaPage.page"
                @current-change="handleCurrentSea"
                hide-on-single-page
              >
              </el-pagination>
            </div>
          </tab-content-item>

          <!-- coc -->
          <tab-content-item
            :active="tabNav == 'Coc'"
            id="coc"
            aria-labelled-by="pills-family-info"
          >
            <h4>{{ $t("Channels.b29@coc") }}</h4>
            <hr />

            <ul class="d-flex flex-wrap suggestions-lists m-0 p-0">
              <li
                v-for="(v, i) in cocList"
                :key="i"
                class="d-flex mb-4 align-items-center"
                style="width: 50%"
              >
                <div class="user-img img-fluid">
                  <img :src="v.avatar" class="rounded-circle avatar-40" />
                </div>
                <div class="media-support-info ml-3">
                  <h6>{{ v.nick_name }}</h6>
                  <p class="mb-0">{{ v.coc_role ? v.coc_role : "-" }}</p>
                </div>
              </li>
            </ul>
          </tab-content-item>

          <!-- Sponsors & Partners暂无用 -->
          <tab-content-item
            :active="tabNav == 'Part'"
            id="Partner"
            aria-labelled-by="pills-about-info"
          >
            <h4>Partnerships</h4>
            <hr />
            <!-- sponsor -->
            <section
              v-if="sponsorLarge.length || sponsorSmall.length"
              class="sponsors mt-0"
            >
              <h3>Sponsors</h3>
              <el-row :gutter="10">
                <el-col :xs="24" :sm="12" v-for="(v, i) in sponsorLarge" :key="i">
                  <div class="img-box">
                    <el-image
                      style="width: 120px; height: 120px"
                      :src="v.logo"
                      fit="cover"
                    ></el-image>
                    <span>{{ v.name }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :xs="24" :sm="12" v-for="(v, i) in sponsorSmall" :key="i">
                  <div class="img-box">
                    <el-image
                      style="width: 120px; height: 120px"
                      :src="v.logo"
                      fit="cover"
                    ></el-image>
                    <span>{{ v.name }}</span>
                  </div>
                </el-col>
              </el-row>
            </section>

            <!-- partner -->
            <section v-if="partners.length" class="partner">
              <h3>Partner</h3>
              <el-row :gutter="10">
                <el-col :span="8" v-for="(v, i) in partners" :key="i">
                  <div class="img-box">
                    <el-image
                      style="width: 120px; height: 120px"
                      :src="v.logo"
                      fit="cover"
                    ></el-image>
                    <span>{{ v.name }}</span>
                  </div>
                </el-col>
              </el-row>
            </section>
          </tab-content-item>

          <!-- Twinning -->
          <tab-content-item
            :active="tabNav == 'Twinn'"
            id="Twinning"
            aria-labelled-by="pills-family-info"
          >
            <div></div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <h4>Twinning</h4>
              <el-select v-model="twinYear" @change="channelTwinning()">
                <el-option
                  :value="item"
                  v-for="(item, index) in yearList"
                  :key="index"
                  >{{ item }}</el-option
                >
              </el-select>
            </div>
            <hr />
            <div >
              <div
                v-for="(item, index) of twinningList"
                :key="index"
                style="
                  cursor: pointer;
                  background: #f5fbff;
                  margin-bottom: 20px;
                  padding: 15px;
                  height: 160px;
                  overflow: hidden;
                  overflow-y: auto;
                "
                @click="showTwinning(item)"
              >
                <h5 class="testHidden">
                  {{ item.title }}
                </h5>
                <p>{{ item.time }}</p>
                <el-row>
                  <el-col :xs="24" :sm="12"
                    class="testHidden p-2"
                    v-for="(v, i) of item.organizations_arr"
                    :key="i"
                  >
                    <!-- <img
                      :src="v.organize_avatar"
                      class="avatar-60 rounded-circle"
                      alt=""
                    /> -->
                    <div class="item_con">
                      <el-avatar
                        fit="cover"
                        :size="60"
                        :src="v.organize_avatar"
                      >
                        <img src="../../../../assets/images/public/user.png" />
                      </el-avatar>
                      <span class="spanitem" :title="v.name">
                        {{ v.name }}</span
                      >
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </tab-content-item>
          <!-- edit organization -->
          <tab-content-item
            :active="tabNav == 'Organ'"
            id="EditOrg"
            aria-labelled-by="pills-contact-info"
          >
            <h4>Edit Organization</h4>
            <hr />
            <div class="row">
              <p class="ml-2">
                Are you a National President, Local President, or Secretary
                General and are looking to make changes to your organization?
                Make sure your jci.cc account has your position applied and
                click the button to sign into the admin portal to manage the
                organization.
              </p>
              <el-button @click="goAdmin" type="primary" round>Login</el-button>
            </div>
          </tab-content-item>
        </div>
      </el-col>
    </el-row>
    <b-modal
      id="twinning"
      :title="tainning.title"
      hide-footer
      class="modal-self"
    >
      <p>{{ tainning.time }}</p>
      <el-row class="con_item" style="max-height: 400px">
        <el-col
          class="testHidden p-2"
          :sm="6"
          :xs="12"
          v-for="(item, index) of tainning.organizations_arr"
          :key="index"
        >
          <!-- <img
            :src="item.organize_avatar"
            class="avatar-60 rounded-circle"
            alt=""
          /> -->
          <div class="item_con">
            <el-avatar fit="cover" :size="60" :src="item.organize_avatar">
              <img src="../../../../assets/images/public/user.png" />
            </el-avatar>
            <span class="spanAlart" :title="item.name"> {{ item.name }}</span>
          </div>
        </el-col>
      </el-row>
    </b-modal>
  </section>
</template>
<script>
export default {
  name: "About",

  props: {
    type: {
      type: Number
    },
    tab: {
      type: Array
    },
    intro: {
      type: Object
    },
    channelDet: {
      type: Object
    },
    cocList: {
      type: Array
    },
    partners: {
      type: Array
    },
    sponsorLarge: {
      type: Array
    },
    sponsorSmall: {
      type: Array
    },
    showEditOrg: {
      type: Boolean
    }
  },

  computed: {
    // bodYearList() {
    //   let bod_list = this.bod_list;
    //   return Object.keys(bod_list);
    // },
    // selectYear: {
    //   set (v) {
    //     console.log(222, v);
    //     return v
    //   },
    //   get (v) {
    //     console.log(11, v);
    //     let bod_list = this.bod_list;
    //     return Object.keys(bod_list)[0]
    //   }
    // },
    // bodListData() {
    //   let bod_list = this.bod_list;
    //   let NewBodList = [];
    //   this.selectYear=Object.keys(bod_list)[0]
    //   this.selectYear = Math.max.apply(null, Object.keys(bod_list))
    //   Object.keys(bod_list).forEach((year) => {
    //     bod_list[year].forEach((list) => {
    //       list.year = year;
    //       NewBodList.push(list);
    //     });
    //   });
    //   return NewBodList;
    // },
  },
  data() {
    return {
      // selectYear: "",
      yearList: [],
      twinYear: "",
      twinningList: [],
      tainning: "",
      bod_list: [],
      member_list: [],
      searchMem: "",
      searchSen: "",
      senator: [],
      bodPage: {
        total: 0,
        page: 1,
        per_page: 6
      },
      memberPage: {
        total: 0,
        page: 1,
        per_page: 12
      },
      seaPage: {
        total: 0,
        page: 1,
        per_page: 12
      },

      tabNav: "Intro"
    };
  },
  mounted() {
    this.getYear();
  },
  methods: {
    checkTab() {
      this.tabNav = "Intro";
    },
    checkMember() {
      this.tabNav = "Membe";
      this.searchMem = "";
      this.searchBodMem();
      // this.getMember();
    },
    checkSenator() {
      this.tabNav = "Senat";
      this.searchSen = "";
      this.seaPage.page = 1;
      this.getOrganizationSenateList();
    },
    goAdmin() {
      if (process.env.VUE_APP_MODE == "development") {
        window.open("https://jci-admin-web.qlclient.com/#/login");
      } else if (process.env.VUE_APP_MODE == "test") {
        window.open("https://admin.jciuatsys.com/#/login");
      } else {
        window.open("https://admin.jci.cc/#/login");
      }
    },
    showTwinning(item) {
      this.tainning = item;
      this.$bvModal.show("twinning");
      // this.$bvModal.hide("twinning");
    },
    getMember() {
      this.searchMem = "";
      this.getOrganizationCareerList();
      this.getOrganizationMembersList();
    },
    handleCurrentBod(val) {
      this.bodPage.page = val;
      this.getOrganizationCareerList();
    },
    searchBodMem() {
      this.memberPage.page = 1;
      this.bodPage.page = 1;
      this.getOrganizationCareerList();
      this.getOrganizationMembersList();
    },
    // bod
    getOrganizationCareerList() {
      this.$http
        .getOrganizationCareerList({
          organization_id: this.channelDet.id,
          per_page: 6,
          keyword: this.searchMem,
          page: this.bodPage.page
        })
        .then(res => {
          if (res.status == 200) {
            this.bod_list = res.data.data;
            this.bodPage.total = res.data.total;
          }
        });
    },
    handleCurrentMem(val) {
      this.memberPage.page = val;
      this.getOrganizationMembersList();
    },
    // member
    getOrganizationMembersList() {
      this.$http
        .getOrganizationMembersList({
          organization_id: this.channelDet.id,
          per_page: 12,
          keyword: this.searchMem,
          page: this.memberPage.page
        })
        .then(res => {
          if (res.status == 200) {
            this.member_list = res.data.data;
            this.memberPage.total = res.data.total;
          }
        });
    },
    handleCurrentSea(val) {
      this.seaPage.page = val;
      this.getOrganizationSenateList();
    },
    searchSenator() {
      this.getOrganizationSenateList();
    },
    getOrganizationSenateList() {
      this.$http
        .getOrganizationSenateList({
          organization_id: this.channelDet.id,
          per_page: 12,
          keyword: this.searchSen,
          page: this.seaPage.page
        })
        .then(res => {
          if (res.status == 200) {
            this.senator = res.data.data;
            this.seaPage.total = res.data.total;
          }
        });
    },
    getYear() {
      let date = new Date();
      this.twinYear = date.getFullYear();
      for (let i = this.twinYear; i >= 2021; i--) {
        this.yearList.push(i);
      }
    },
    channelTwinning() {
      this.$http
        .channelTwinning({
          year: this.twinYear,
          channel_id: this.$route.query.channel_id
        })
        .then(res => {
          if (res.status == 200) {
            this.twinningList = res.data;
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@media (min-width: 600px) {
  .phoneTaps {
    display: none;
  }
    .bod_container {
      max-height: 460px;
      overflow-y: auto;
    }
  }
  @media (max-width: 600px) {
    .pcTaps{
      display: none;
    }
    .tab-content{
      padding: 0 12px;
    }
    .bod_container {
      max-height: 460px;
      // overflow-y: auto;
    }
    h4{
      font-size: 14px !important;
    }
    .media-support-info{
      >h6 {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 110px;
      }
      p{
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 110px;
      }
    }
    .introDet{
      ::v-deep >p{
        font-size: 14px; 
      }
      .el-row{
        font-size: 10px;
        padding-left: 2px;
      }
    }
  }
.yellow-text-bg {
  padding: 12px 8px;
  background-color: #fffcf3;
  color: #ffba68;
}
.searchInt {
  padding-bottom: 10px;
  ::v-deep .el-input__inner {
    border-radius: 10px;
  }
}


.testHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .item_con {
    display: flex;
    align-items: center;

    .el-avatar {
      border: 1px solid #eee;
    }

    .spanitem {
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    .spanAlart {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
      max-width: 120px;
    }
  }
}

.con_item {
  max-height: 600px;
  overflow-y: scroll;
}

.chairman-left {
  position: relative;
  padding-right: 20px;

  li {
    margin-bottom: 22px;
    color: #333;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #f1f1f1;
    position: absolute;
    top: 0;
    right: -15px;
  }
}

::v-deep #twinning .modal-dialog {
  max-width: 800px !important;
}

.chairman-right {
  padding-left: 20px;

  li {
    margin-bottom: 22px;
    color: #666;
  }
}

.partner,
.sponsors {
  // margin-top: 50px;

  > h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
}

.img-box {
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  > span {
    color: #333;
    padding-top: 10px;
    text-decoration: underline;
  }
}
</style>
