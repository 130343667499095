<template>
  <b-row>
    <b-col sm="12">
      <iq-card v-show="fileList.length">
        <div class="p-3 d-flex align-items-center justify-content-between">
          <h4 class="card_title">{{ $t("Channels.084@document") }}</h4>
          <el-input
            clearable
            @clear="channelFile()"
            @keyup.enter.native="channelFile()"
            placeholder=""
            v-model="keyword"
            style="width: 200px"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search cursor-pointer"
              @click="channelFile()"
            ></i>
          </el-input>
        </div>
        <!-- <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t("Channels.084@document") }}</h4>
        </template> -->
        <!-- <template v-slot:body>
          <table class="files-lists table table-striped">
            <tbody>
              <tr v-for="(item, index) in fileList" :key="index">
                <td class="icon_i">
                  <i v-if="item.file_img == 0" class="ri-file-unknow-line"></i>
                  <i v-if="item.file_img == 1">
                    <img src="@/assets/images/channel/word.png" alt="" />
                  </i>
                  <i v-if="item.file_img == 2">
                    <img src="@/assets/images/channel/pdf.png" alt="" />
                  </i>
                  <i v-if="item.file_img == 3" class="ri-file-zip-line"></i>
                  <i
                    style="color: #f7514e"
                    v-if="item.file_img == 4"
                    class="ri-file-4-line"
                  ></i>
                  <i v-if="item.file_img == 5">
                    <img src="@/assets/images/channel/excel.png" alt="" />
                  </i>
                  <i
                    style="color: #50b5ff"
                    v-if="item.file_img == 6"
                    class="ri-image-line"
                  ></i>
                  {{ item.file_name }}
                </td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.file_size }}</td>
                <td>
                  <div class="flex align-items-center list-user-action">
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Download"
                      :href="item.file_path"
                      ><i class="ri-download-cloud-line"></i
                      ><span style="font-size: 14px; margin-left: 5px"
                        >Download</span
                      >
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template> -->
        <section class="pb-5 pt-3">
          <el-table
            :data="fileList"
            style="width: 100%"
            :show-header="false"
            class="my_table"
          >
            <el-table-column width="60">
              <template slot-scope="scope">
                <div class="icon_i">
                  <i
                    v-if="scope.row.file_img == 0"
                    class="ri-file-unknow-line"
                  ></i>
                  <i v-if="scope.row.file_img == 1">
                    <img src="@/assets/images/channel/word.png" alt="" />
                  </i>
                  <i v-if="scope.row.file_img == 2">
                    <img src="@/assets/images/channel/pdf.png" alt="" />
                  </i>
                  <i
                    v-if="scope.row.file_img == 3"
                    class="ri-file-zip-line"
                  ></i>
                  <i
                    style="color: #f7514e"
                    v-if="scope.row.file_img == 4"
                    class="ri-file-4-line"
                  ></i>
                  <i v-if="scope.row.file_img == 5">
                    <img src="@/assets/images/channel/excel.png" alt="" />
                  </i>
                  <i
                    style="color: #50b5ff"
                    v-if="scope.row.file_img == 6"
                    class="ri-image-line"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="file_name" min-width="150">
            </el-table-column>
            <el-table-column prop="created_at" show-overflow-tooltip min-width="150">
            </el-table-column>
            <el-table-column prop="file_size" show-overflow-tooltip >
            </el-table-column>
            <el-table-column width="150">
              <template slot-scope="scope">
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      my_download
                      cursor-pointer
                    "
                    @click="downloadFile(scope.row.file_path,scope.row.file_name)"
                    ><i class="ri-download-cloud-line"></i
                    ><span style="font-size: 14px; margin-left: 5px"
                      >Download</span
                    >
                  </a>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </iq-card>
      <el-card v-show="!fileList.length" style="background:#fff;" class="pt-5 pb-5">
        <!-- <img
          src="../../../../assets/images/group/empty1.png"
          alt=""
          style="width:250px; margin: 100px auto; display: block"
        /> -->
      </el-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "File",

  props: ["fileList"],
  data() {
    return { keyword: "" };
  },
  methods: {
    // 获取频道文件
    channelFile() {
      this.$http
        .channelFile({
          channel_id: this.$route.query.channel_id,
          keyword: this.keyword,
        })
        .then((res) => {
          if (res.status == 200) {
            // this.fileList = res.data;
            // console.log(111, this.fileList);
            this.$emit("update:fileList", res.data);
          }
        });
    },

    downloadFile(url, fileName) {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
  },
};
</script>

<style lang="scss" scoped>
.icon_i {
  i {
    font-size: 30px;
    margin-right: 5px;
  }
}

.my_download {
  display: block;
  width: 136px !important;
  height: 35px;
  background: #f5fbff;
  border-radius: 18px;
}

.my_table {
  font-size: 12px;
  ::v-deep tbody tr td {
    text-align: center !important;
  }
  ::v-deep tbody tr td:nth-child(1) {
    text-align: right !important;
  }
  ::v-deep tbody tr td:nth-child(4) {
    // text-align: right !important;
  }
  ::v-deep tbody tr td:nth-child(2) {
    text-align: left !important;
  }
  ::v-deep .el-table::before {
    width: 0 !important;
  }
  ::v-deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }
}
</style>
