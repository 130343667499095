<template>
  <div class="iq-card-body p-0">
    <b-row>
      <b-col lg="4">
        <!-- About -->
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("Channels.de9@about") }}</h4>
          </template>
          <template v-slot:body>
            <!-- JCI 专页 -->
            <ul
              class="about-box m-0 p-0"
              v-if="channelDet.type >= 21 && channelDet.type <= 25"
            >
              <li v-if="channelDet.about.description">
                <div
                  class="descStyle"
                  v-html="channelDet.about.description"
                ></div>
                <div style="text-align: right">
                  <a class="cursor-pointer" @click="$emit('checkAbout')"
                    >more</a
                  >
                </div>
              </li>
              <!-- <li v-if="channelDet.about.introduction" class="d-flex">
                <div class="stories-data">
                  <p>
                    {{ channelDet.about.introduction }}
                  </p>
                </div>
              </li> -->
              <li class="d-flex">
                <i style="margin-top: -8px">
                  <img
                    src="../../../../assets/images/channel/year.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.3a0@year_of_establishmen") }}</h5>
                  <p class="mb-0">
                    {{ channelDet.about.establishment | filterNone }}
                  </p>
                </div>
              </li>
              <li class="d-flex">
                <i style="margin-top: -6px">
                  <img
                    src="../../../../assets/images/channel/lang.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.f90@official_language") }}</h5>
                  <p class="mb-0">
                    {{ channelDet.about.language | filterNone }}
                  </p>
                </div>
              </li>
              <li class="d-flex">
                <i class="las" style="margin-top: -6px">
                  <img
                    src="../../../../assets/images/channel/gender.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.602@member_gender") }}</h5>
                  <p class="mb-0">
                    <span v-if="channelDet.about.gender == ''">-</span>
                    <span v-else>{{
                      channelDet.about.gender | filterGender
                    }}</span>
                  </p>
                </div>
              </li>
            </ul>
            <!-- 项目 专页 -->
            <ul
              class="about-box m-0 p-0"
              v-if="channelDet.type >= 1 && channelDet.type <= 4"
            >
              <li class="d-flex">
                <i class="las" style="margin-top: -6px">
                  <img
                    src="../../../../assets/images/channel/time.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <!-- <h5>{{ channelDet.type | typeFilter }} Period</h5> -->
                  <h5>Time</h5>
                  <p
                    class="mb-0"
                    style="
                      width: 240px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ about.time | filterNone }}
                  </p>
                </div>
              </li>
              <!-- <li class="d-flex">
                <svg
                  class="mr-2 mt-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                    fill="rgba(120,120,120,0.98)"
                  />
                </svg>
                <div class="stories-data">
                  <h5>Time Zone</h5>
                  <p
                    style="
                      width: 240px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    class="m-0"
                  >
                    {{ about.time_zone | filterNone }}
                  </p>
                </div>
              </li> -->
              <li class="d-flex">
                <i style="margin-top: -6px">
                  <img
                    src="../../../../assets/images/channel/lang.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.bc1@language") }}</h5>
                  <p class="mb-0">
                    {{ about.language && about.language.join("/") }}
                  </p>
                </div>
              </li>
              <li class="d-flex">
                <i style="margin-top: -8px">
                  <img
                    src="../../../../assets/images/channel/organization.png"
                    alt=""
                  />
                </i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.107@organazition") }}</h5>
                  <p class="mb-0">
                    {{ about.organization_name | filterNone }}
                  </p>
                </div>
              </li>
              <li class="d-flex my_link">
                <i class="ri-link-m"></i>
                <div class="stories-data">
                  <h5>{{ channelDet.type | typeFilter }} Link</h5>
                  <router-link
                    v-if="about.operation_type == 1"
                    :to="{
                      path: `/${computedRouterPath}-detail`,
                      query: { id: about.link_id },
                    }"
                  >
                    {{ $t("Channels.549@click_here") }}</router-link
                  >
                  <p v-else class="mb-1">Has been removed</p>
                </div>
              </li>
            </ul>
            <!-- 合作伙伴专页  和 商家专页 -->
            <ul
              class="about-box m-0 p-0"
              v-if="channelDet.type >= 11 && channelDet.type <= 13"
            >
              <!-- <li v-if="channelDet.about.introduction" class="d-flex">
                <div class="stories-data">
                  <p>
                    {{ channelDet.about.introduction }}
                  </p>
                </div>
              </li> -->
              <li v-if="channelDet.about.introduction">
                <div v-if="channelDet.about.introduction.length > 200 && flag">
                  <span v-html="channelDet.about.introduction"></span>
                  <a class="cursor-pointer" @click="flag = false">more</a>
                </div>
                <span v-else v-html="channelDet.about.introduction"></span>
              </li>
              <li class="d-flex">
                <i class="ri-phone-line" style="margin-top: -6px"></i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.59b@mobile") }}</h5>
                  <p class="mb-0">{{ channelDet.about.phone | filterNone }}</p>
                </div>
              </li>
              <li class="d-flex">
                <i class="ri-mail-line" style="margin-top: -6px"></i>
                <div class="stories-data">
                  <h5>{{ $t("Channels.f33@email") }}</h5>
                  <p class="mb-0">{{ channelDet.about.email | filterNone }}</p>
                </div>
              </li>
              <li class="d-flex">
                <i class="ri-global-line" style="margin-top: -6px"></i>
                <div class="stories-data">
                  <h5>Link</h5>
                  <span
                    @click="toLinkSider(channelDet.about.link)"
                    style="color: #50b5ff; cursor: pointer"
                    >{{ channelDet.about.link | filterNone }}</span
                  >
                </div>
              </li>
            </ul>
          </template>
        </iq-card>

        <!-- album -->
        <iq-card v-if="computedLength">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("Channels.d90@album") }}</h4>
          </template>
          <template v-slot:body>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li
                class="col-md-4 col-6 p-1"
                style="height: 75px"
                v-for="(photo, index) in channelDet.albums"
                :key="index"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="photo.path"
                  :preview-src-list="viewPhotoList"
                >
                </el-image>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <div v-if="showCreatPost">
          <AddSocialPost
            @addPost="addPost"
            :isShowAddPostModal="isHideIcon"
            :friendList="friendsList"
            :noShowPermission="true"
          >
          </AddSocialPost>
        </div>
        <div
          v-show="socialPostsList.length"
          v-for="post in socialPostsList"
          :key="post.id"
        >
          <SocialPost
            :post="post"
            @SharePostDetail="getSharePostDetStatus"
          ></SocialPost>
        </div>
        <div v-if="loading">
          <el-skeleton animated :count="2">
            <template slot="template">
              <iq-card body-class="p-0">
                <div style="padding: 14px" class="d-flex align-items-center">
                  <el-skeleton-item
                    variant="circle"
                    style="width: 60px; height: 60px"
                  />
                  <div class="ml-3 d-flex flex-column">
                    <el-skeleton-item variant="text" style="width: 120px" />
                    <el-skeleton-item
                      variant="text"
                      style="width: 80px; margin-top: 10px"
                    />
                  </div>
                </div>
                <div
                  style="padding: 20px"
                  class="d-flex justify-content-between mt-5"
                >
                  <el-skeleton-item variant="text" style="width: 60px" />
                  <el-skeleton-item variant="text" style="width: 60px" />
                  <el-skeleton-item variant="text" style="width: 60px" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </div>
        <p v-if="noMore && socialPostsList.length" class="no_more_common">
          There's no more content
        </p>
        <el-card v-show="!socialPostsList.length">
          <div style="background:#fff;">
            <img
              src="@/assets/images/group/empty1.png"
              alt=""
              style="margin: 100px auto; width:250px; display: block"
            />
          </div>
        </el-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SocialPost from "./ChannelPost.vue";
import AddSocialPost from "./AddPost.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      socialPostsList: [],
      url: [],
      computedLength: 0,
      flag: true,
      loading: true,
      noMore: false,
      isHideIcon: true,
      page: 1,
      friendsList: [],
    };
  },

  props: ["channelDet", "showCreatPost"],

  components: { SocialPost, AddSocialPost },

  created() {
    this.getPostList();
    this.userFriendList();
    this.url = window.location.href.split("/");
  },

  mounted() {
    this.$nextTick(() => {
      this.$on("funInfinitePost", () => {
        this.page++;
        this.getPostList();
      });
    });
  },

  filters: {
    typeFilter(val) {
      let type = { 1: "Project", 2: "Event", 3: "Course", 4: "Meeting" };
      return type[val];
    },
    filterNone(val) {
      return val && val.trim() ? val : "-";
    },
    filterDes(val) {
      return val.substring(0, 200) + "...";
    },
  },

  computed: {
    ...mapState({
      about: (state) => state.Channel.about,
    }),
    viewPhotoList() {
      let imgList = this.channelDet.albums;
      let newArray = [];
      imgList.forEach((item) => {
        newArray.push(item.path);
      });
      return newArray;
    },
    computedRouterPath() {
      let val = this.channelDet.type;
      let type = { 1: "project", 2: "event", 3: "course", 4: "meeting" };
      return type[val];
    },
  },

  watch: {
    channelDet() {
      this.computedLength = this.channelDet.albums.length;
    },
  },

  methods: {
    getCreat() {
      this.socialPostsList = [];
      this.getPostList();
      this.userFriendList();
      this.url = window.location.href.split("/");
    },
    // 发帖
    addPost() {
      let [post, permission, shareData, type] = arguments;
      let postData = {
        content: "",
        is_public: permission,
        media: {},
        friends: [],
        location: "",
        longitude: "",
        latitude: "",
      };
      Object.keys(postData).forEach((key) => {
        postData[key] = post[key] || postData[key];
      });
      this.$http
        .operationChannelPost({
          type: "add_post",
          channel_id: this.$route.query.channel_id,
          ...postData,
        })
        .then((res) => {
          if (res.status == 200) {
            if (post.share_id) {
              shareData.share_total++;
            } else if (!type || type != "org") {
              this.getPostList();
            }
          }
        });
    },
    userFriendList() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.$http
        .userFriendList({
          user_id: userInfo.user_id,
          type: 4,
        })
        .then((res) => {
          let list = res.data;
          this.friendsList = list.length > 9 ? list.splice(0, 9) : list;
        });
    },
    getPostList() {
      this.loading = true;
      this.$http
        .getChannelPost({
          channel_id: this.$route.query.channel_id,
          organization_id:this.$route.query.id,
          page: this.page,
          size: 10,
        })
        .then((res) => {
          if (res.status == 200) {
            this.socialPostsList = [...this.socialPostsList, ...res.data.data];
          }
          if (res.data.current_page == res.data.last_page) {
            this.$emit("fatherMethod");
            this.noMore = true;
          }
          setTimeout(() => {
            this.loading = false;
          }, 50);
        });
    },
    getSharePostDetStatus(post) {
      this.$router.push({
        path: `/newsfeeds/${post.info_url}`,
      });
      // this.isShowHomePost = false;
      // this.getPostDetail(post.info_url);
    },
    toLinkSider(link) {
      // window.location.href = `https://${link}`;
      window.open(`https://${link}`);
    },
  },
};
</script>
<style lang="scss" scoped>
// about-icon

.about-box {
  li {
    margin-bottom: 20px;
    .descStyle {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5; //行数
      display: -webkit-box;
    }

    h5 {
      font-size: 14px;
      color: #333;
    }

    p {
      color: #999;
    }

    i {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}

.my_link {
  i {
    font-weight: 700;
    font-size: 20px !important;
    color: #333;
    margin-top: -8px;
  }
}

@media (max-width: 991px) {
  .stories-data p {
    width: auto !important;
  }
}
</style>
