<template>
  <iq-card class="Shop">
    <template v-slot:headerTitle>
      <h5>{{$t('Channels.db2@hot_products')}}</h5>
    </template>
    <template v-slot:body>
      <b-row>
        <b-col sm="12" md="3" lg="3">
          <iq-card body-class="text-center">
            <div class="img-card">
              <div slot="cardImage" class="top-bg-image">
                <img
                  src="@/assets/images/page-img/p1.jpg"
                  class="img-fluid"
                  alt="group-bg"
                />
              </div>
              <div class="card-text-box">
                <span>TRANDMON</span>
                <p><span>$90.00</span><span>$90.00</span></p>
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
    </template>
  </iq-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.card-text-box {
  padding: 10px 15px 6px 10px;
  > span {
    width: 88px;
    height: 10px;
    opacity: 1;
    font-size: 14px;
    font-family: Montserrat Medium, Montserrat Medium-Medium;
    font-weight: 500;
    text-align: center;
    color: #666666;
  }
  > p {
    font-size: 16px;
    padding-top: 5px;
    margin: 0;
    > span:first-child {
      color: #000;
      font-family: Poppins SemiBold, Poppins SemiBold-SemiBold;
      margin-right: 9px;
      font-weight: 550;
    }

    > span:last-child {
      color: #999;
      font-family: Poppins Regular, Poppins Regular-Regular;
      text-decoration: line-through;
    }
  }
}
</style>